<template>
  <div class="Luft">
    <img class="refresh" src="../assets/refresh.png" v-on:click="getData()" />
    <h1>{{standortName}}</h1>
    <h2 v-if="dayData.length > 0">🌡 {{dayData.at(-1).temp.toFixed(1).replace('.', ',')}} °C | 💧 {{dayData.at(-1).hum.toFixed(1).replace('.', ',')}} %</h2>
    <div class="chart">
      <canvas id="dayChart" />
    </div>
    <div class="chart">
      <canvas id="weekChart" />
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import moment from 'moment'
import 'chartjs-adapter-moment'
import axios from 'axios'
import Konstanten from '../scripts/Konstanten'

export default {
  name: 'LuftView',
  mixins: [Konstanten],
  data () {
    return {
      standortId: null,
      standortName: 'lädt...',
      dayChart: null,
      dayData: [],
      weekChart: null,
      weekData: []
    }
  },

  methods: {
    getData: function () {
      axios.post(this.getDataURL(), {
        id: this.standortId,
        airId: this.standortId,
        Luftdaten: true
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.standortName = response.data.name
        this.dayData = response.data.luft.day
        this.weekData = response.data.luft.week
      }).then(() => {
        this.drawCharts()
      })
    },

    drawCharts: function () {
      moment.lang('de')
      this.drawDayChart()
      this.drawWeekChart()
    },

    drawDayChart: function () {
      const datasets = [{
        type: 'line',
        label: 'Temperatur',
        yAxisID: 'yTemperatur',
        data: this.dayData.map(row => {
          return {
            x: moment(row.Zeit * 1000),
            y: row.temp
          }
        }),
        borderColor: '#000052'
      },
      {
        type: 'line',
        label: 'Luftfeuchtigkeit',
        yAxisID: 'yHumidity',
        data: this.dayData.map(row => {
          return {
            x: moment(row.Zeit * 1000),
            y: row.hum
          }
        }),
        borderColor: '#C8C8FF'
      }]
      if (this.dayChart) {
        this.dayChart.destroy()
      }
      this.dayChart = new Chart(document.getElementById('dayChart'), {
        type: 'line',
        data: {
          datasets: datasets
        },
        options: {
          animations: false,
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
          },
          plugins: {
            title: {
              display: true,
              text: 'Letzte 24 Stunden'
            },
            legend: {
              position: 'bottom'
            }
          },
          elements: {
            line: {
              tension: 0,
              backgroundColor: 'transparent'
            },
            point: {
              radius: 0
            }
          },
          scales: {
            xAxis: {
              type: 'time',
              time: {
                unit: 'hour',
                round: 'minute',
                displayFormats: {
                  hour: 'H:00',
                  minute: 'HH:mm'
                },
                tooltipFormat: 'DD.MM. HH:mm'
              }
            },
            yTemperatur: {
              position: 'left',
              title: {
                display: true,
                text: 'Temperatur'
              },
              type: 'linear'
            },
            yHumidity: {
              position: 'right',
              title: {
                display: true,
                text: 'Luftfeuchtigkeit'
              },
              type: 'linear'
            }
          }
        }
      })
    },

    drawWeekChart: function () {
      const datasets = [{
        type: 'line',
        label: 'Temperatur',
        yAxisID: 'yTemperatur',
        data: this.weekData.map(row => {
          return {
            x: moment(row.Zeit * 1000),
            y: row.temp
          }
        }),
        borderColor: '#000052'
      },
      {
        type: 'line',
        label: 'Luftfeuchtigkeit',
        yAxisID: 'yHumidity',
        data: this.weekData.map(row => {
          return {
            x: moment(row.Zeit * 1000),
            y: row.hum
          }
        }),
        borderColor: '#C8C8FF'
      }]
      if (this.weekChart) {
        this.weekChart.destroy()
      }
      this.weekChart = new Chart(document.getElementById('weekChart'), {
        type: 'line',
        data: {
          datasets: datasets
        },
        options: {
          animations: false,
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
          },
          plugins: {
            title: {
              display: true,
              text: 'Letzte Woche'
            },
            legend: {
              position: 'bottom'
            }
          },
          elements: {
            line: {
              tension: 0,
              backgroundColor: 'transparent'
            },
            point: {
              radius: 0
            }
          },
          scales: {
            xAxis: {
              type: 'time',
              time: {
                unit: 'day',
                round: 'minute',
                displayFormats: {
                  day: 'ddd D.M. ',
                  hour: 'H:00',
                  minute: 'HH:mm'
                },
                tooltipFormat: 'DD.MM. HH:mm'
              }
            },
            yTemperatur: {
              position: 'left',
              title: {
                display: true,
                text: 'Temperatur'
              },
              type: 'linear'
            },
            yHumidity: {
              position: 'right',
              title: {
                display: true,
                text: 'Luftfeuchtigkeit'
              },
              type: 'linear'
            }
          }
        }
      })
    }
  },

  mounted: function () {
    this.standortId = this.$route.params.standortId
    localStorage.standortId = this.standortId
    this.getData()
    setInterval(this.getData, 5 * 60 * 1000)
  }
}
</script>

<style scoped>

h1 {
  margin: 0;
  font-size: 2.25em;
  color: var(--darkBlue);
  text-decoration: underline;
}

h2 {
  margin: 0.5em 0 0 0;
  font-size: 1.5em;
  text-align: center;
  color: var(--lightBlue);
}

.chart {
  width: 100%;
  height: 35vh;
}

.refresh {
  float: right;
  height: 3em;
  width: 6em;
  background: var(--lightBlue);
  object-fit: contain;
}

</style>
