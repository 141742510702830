<template>
  <div class="Standort">
    <span v-if="hasLuftdaten">
      <router-link :to="'/' + standortId">LED</router-link>
      <router-link :to="'/' + standortId + '/luft'">Luftdaten</router-link>
    </span>
    <router-view :key="$route.path" />
  </div>
</template>

<script>
import axios from 'axios'
import Konstanten from '../scripts/Konstanten'

export default {
  name: 'StandortView',
  mixins: [Konstanten],
  data () {
    return {
      standortId: null,
      hasLuftdaten: true
    }
  },

  methods: {
    getData: function () {
      axios.post(this.getDataURL(), {
        id: this.standortId
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.hasLuftdaten = response.data.hasLuftdaten
      })
    }
  },

  mounted: function () {
    this.standortId = this.$route.params.standortId
    localStorage.standortId = this.standortId
    this.getData()
  }
}
</script>

<style scoped>

a {
  width: 50%;
  float: left;
  text-align: center;
  background: var(--lightBlue);
  color: var(--gray);
  text-decoration: none;
  font-size: 1.5em;
  border-bottom: 5px solid var(--darkBlue);
  margin-bottom: 0.5em;
}

a.router-link-exact-active {
  color: var(--darkBlue);
}

</style>
