<template>
  <div class="Uebersicht">
    {{standortId}}
  </div>
</template>

<script>
export default {
  name: 'UebersichtView',
  data () {
    return {
      standortId: null
    }
  },

  mounted: function () {
    this.standortId = localStorage.getItem('standortId') ? localStorage.getItem('standortId') : 1
    this.$router.replace('/' + this.standortId)
  }
}
</script>

<style scoped>

</style>
