<template>
  <div class="LED">
    <h1 v-if="!standortName">lädt...</h1>
    <span v-if="standortName">
      <img v-if="power" class="power" src="../assets/lampe-an.png" v-on:click="setPower(false)" />
      <img v-if="!power" class="power" src="../assets/lampe-aus.png" v-on:click="setPower(true)" />
      <h1>{{standortName}}</h1>
      <h2>Effekt</h2>
      <select v-model="effect" v-on:change="setEffect">
        <option :value="3">Regenbogen</option>
        <option :value="1">Einfarbig</option>
        <option :value="2">Halb</option>
      </select>
      <h2>Helligkeit</h2>
      <input v-model="brightness" v-on:input="setBrightness()" type="range" min="1" max="255" />
      <span v-if="effect === 1 || effect === 2">
        <h2>Farbe</h2>
        <div v-on:click="setColor('#ffffff')" :class="{ active: color === '#ffffff'}" class="colorButtonWhite" style="background: #ffffff;" />
        <div v-on:click="setColor('#ff0000')" :class="{ active: color === '#ff0000'}" class="colorButton" style="background: #ff0000;" />
        <div v-on:click="setColor('#ff8800')" :class="{ active: color === '#ff8800'}" class="colorButton" style="background: #ffff00;" />
        <div v-on:click="setColor('#00ff00')" :class="{ active: color === '#00ff00'}" class="colorButton" style="background: #00ff00;" />
        <div v-on:click="setColor('#0000ff')" :class="{ active: color === '#0000ff'}" class="colorButton" style="background: #0000ff;" />
        <div v-on:click="setColor('#ff0044')" :class="{ active: color === '#ff0044'}" class="colorButton" style="background: #ff00aa;" />
        <div v-on:click="setColor('#ff3300')" :class="{ active: color === '#ff3300'}" class="colorButton" style="background: #ff8800;" />
        <div v-on:click="setColor('#00ff55')" :class="{ active: color === '#00ff55'}" class="colorButton" style="background: #00ffaa;" />
        <div v-on:click="setColor('#00ffff')" :class="{ active: color === '#00ffff'}" class="colorButton" style="background: #00ffff;" />
      </span>
      <span v-if="effect === 3">
        <h2>Geschwindigkeit</h2>
        <input v-model="speed" v-on:input="setSpeed()" type="range" min="0" max="100" />
      </span>
      <!-- direction -->
      <h2>Timer</h2>
      <div id="timer" v-on:click="setTimer(null)">⏲ {{timer ? formatTime(new Date(timer * 1000)) : '---'}}</div>
      <input type="hidden" name="timer" value="" />
      <div class="timerButton" v-on:click="setTimer(60)" style="margin-left: 0;">⏲ 0:01</div>
      <div class="timerButton" v-on:click="setTimer(300)">⏲ 0:05</div>
      <div class="timerButton" v-on:click="setTimer(600)">⏲ 0:10</div>
      <div class="timerButton" v-on:click="setTimer(1800)" style="margin-right: 0;">⏲ 0:30</div>
      <div class="timerButton" v-on:click="setTimer(3600)" style="margin-left: 0;">⏲ 1:00</div>
      <div class="timerButton" v-on:click="setTimer(7200)">⏲ 2:00</div>
      <div class="timerButton" v-on:click="setTimer(10800)">⏲ 3:00</div>
      <div class="timerButton" v-on:click="setTimer(18000)" style="margin-right: 0;">⏲ 5:00</div>
    </span>
  </div>
</template>

<script>
import axios from 'axios'
import Konstanten from '../scripts/Konstanten'

export default {
  name: 'SteuerungView',
  mixins: [Konstanten],
  data () {
    return {
      ledId: null,
      standortName: null,
      power: null,
      effect: null,
      color: null,
      brightness: null,
      speed: null,
      timer: null
    }
  },

  methods: {
    setPower: function (power) {
      this.power = power
      axios.post(this.getFunctionsURL(), {
        ledId: this.ledId,
        power: this.power
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data) console.log(response.data)
      })
    },

    setEffect: function () {
      axios.post(this.getFunctionsURL(), {
        ledId: this.ledId,
        effect: this.effect
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data) console.log(response.data)
      })
    },

    setColor: function (color) {
      this.color = color
      axios.post(this.getFunctionsURL(), {
        ledId: this.ledId,
        color: this.color
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data) console.log(response.data)
      })
    },

    setBrightness: function () {
      axios.post(this.getFunctionsURL(), {
        ledId: this.ledId,
        brightness: this.brightness
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data) console.log(response.data)
      })
    },

    setSpeed: function () {
      axios.post(this.getFunctionsURL(), {
        ledId: this.ledId,
        speed: this.speed
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data) console.log(response.data)
      })
    },

    setTimer: function (timer) {
      this.timer = timer !== null ? timer + parseInt(new Date().getTime() / 1000) : null
      axios.post(this.getFunctionsURL(), {
        ledId: this.ledId,
        timer: this.timer
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data) console.log(response.data)
      })
    },

    formatTime: function (time) {
      return time.getHours() + ':' + (time.getMinutes() < 10 ? '0' : '') + time.getMinutes()
    },

    getData: function () {
      axios.post(this.getDataURL(), {
        id: this.ledId
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.standortName = response.data.name
        this.power = response.data.power
        this.effect = response.data.effect
        this.color = response.data.color
        this.brightness = response.data.brightness
        this.speed = response.data.speed
        this.timer = response.data.timer
      })
    }
  },

  mounted: function () {
    this.ledId = this.$route.params.standortId
    localStorage.standortId = this.ledId
    this.getData()
    setInterval(this.getData, 60 * 1000)
  }
}
</script>

<style scoped>

h1 {
  margin: 0;
  font-size: 2.25em;
  color: var(--darkBlue);
  text-decoration: underline;
}

h2 {
  margin: 0.5em 0 0 0;
  font-size: 1.5em;
  color: var(--lightBlue);
}

select {
  width: calc(98vw);
  padding: 5px;
  border: 3px solid var(--darkBlue);
  background: var(--lightBlue);
  font-size: 1.2em;
  font-weight: bold;
  color: var(--darkBlue);
}

input[type=range] {
  height: 2em;
  width: calc(98vw - 6px);
  border: 3px solid var(--darkBlue);
  margin: 0;
  background: var(--lightBlue);
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
}
input::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 2em;
  background: var(--darkBlue);
  border-radius: 0;
  border: 0
}
input::-moz-range-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 2em;
  background: var(--darkBlue);
  border-radius: 0;
  border: 0
}
input::-ms-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 2em;
  background: var(--darkBlue);
  border-radius: 0;
  border: 0
}

.power {
  float: right;
  height: 4em;
  width: 6em;
  background: var(--lightBlue);
  object-fit: contain;
}

.colorButton {
  width: calc(98vw / 4 - 8px);
  border: 4px solid var(--white);
  height: 3em;
  display: inline-block;
}

.colorButtonWhite {
   width: calc(98vw - 8px);
   border: 4px solid var(--lightBlue);
   height: 3em;
   display: inline-block;
}

.colorButton.active, .colorButtonWhite.active {
  border-color: var(--darkBlue);
}

#timer {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
}

.timerButton {
  width: calc((98vw - 24px) / 4 - 8px);
  border: 4px solid var(--darkBlue);
  font-size: 1.25em;
  text-align: center;
  display: inline-block;
  margin: 4px;
  cursor: pointer;
}

</style>
