<template>
  <div id="app">
    <header>
      <h1>LED-Steuerung</h1>
    </header>
    <router-view />
  </div>
</template>

<style>
:root {
  --darkBlue: #000052;
  --lightBlue: #C8C8FF;
  --black: #000000;
  --lightGray: #CCCCCC;
  --gray: #888888;
  --white: #FFFFFF;
}

body {
  margin: 0;
  user-select: none;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  width: 98vw;
  height: 86vh;
  overflow: auto;
  padding: 1vh 1vw;
}

header {
  width: 100vw;
  height: 6vh;
  background: var(--lightBlue);
  overflow: auto;
}

header img {
  height: 100%;
  float: right;
}

header h1 {
  color: var(--white);
  font-size: 5vh;
  line-height: 5vh;
  display: inline-block;
  margin: 0.5vh;
  font-weight: bold;
}
</style>
